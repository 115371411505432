import React from 'react';
import { NamedLink } from '../../../../components';
import { useConfiguration } from '../../../../context/configurationContext';
import css from './CategoryModal.module.css';

const data = [
  {
    id: 1,
    label: 'How it works',
    path: 'how-it-works',
  },
  {
    id: 2,
    label: 'How to sell',
    path: 'how_to_sell',
  },
  {
    id: 3,
    label: 'About',
    path: 'about',
  },
  {
    id: 4,
    label: 'Help',
    path: 'help',
  },
];

function CategoryModal() {
  const config = useConfiguration();
  const categories = config?.categoryConfiguration?.categories ?? [];

  return (
    <div className={css.root}>
      <div className={css.content}>
        <span className={css.greeting}>Browse categories</span>

        <div className={css.accountLinksWrapper}>
          {[{ id: 'gifts', name: 'Gifts' }, ...categories, { id: 'sale', name: 'Sale' }].map(
            (category, index) => {
              return (
                <div key={index}>
                  {category.id === 'gifts' ? (
                    <NamedLink name="CMSPage" params={{ pageId: 'gifts' }} className={css.inbox}>
                      {category.name}
                    </NamedLink>
                  ) : (
                    <NamedLink
                      name="SearchPage"
                      className={css.inbox}
                      to={{
                        search:
                          category.id === 'sale'
                            ? `?pub_discountAvailable=yes`
                            : `?pub_categoryLevel1=${category.id}`,
                      }}
                    >
                      {category.name}
                    </NamedLink>
                  )}
                </div>
              );
            }
          )}
        </div>

        <div className={css.dataLinksWrapper}>
          {data.map((elm, index) => {
            return (
              <div key={index}>
                <NamedLink name="CMSPage" className={css.greeting} params={{ pageId: elm.path }}>
                  {elm.label}
                </NamedLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;
